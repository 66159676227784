import React, { useState } from "react";

type Props = {
  children: React.ReactNode;
};

type Default = {
  showEditForm: boolean;
  setShowEditForm: React.Dispatch<React.SetStateAction<boolean>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
};

const SelectedCamPageContext = React.createContext({} as Default);

function SelectedCamPageProvider({ children }: Props) {
  const [showEditForm, setShowEditForm] = useState(false);
  const [error, setError] = useState("");
  return (
    <SelectedCamPageContext.Provider
      value={{ showEditForm, setShowEditForm, error, setError }}
    >
      {children}
    </SelectedCamPageContext.Provider>
  );
}

function useSelectedCamPageProvider() {
  const context = React.useContext(SelectedCamPageContext);
  if (context === undefined) {
    throw new Error(
      "useSelectedCamPageProvider must be used within a SelectedCamPageProvider"
    );
  }
  return context;
}

export { SelectedCamPageProvider, useSelectedCamPageProvider };
