import { createSlice } from "@reduxjs/toolkit";

interface CamerasState {
  cameras: CameraType[];
}

type coord = [number, number];
type polygon = coord[];

export interface CameraType {
  id: string;
  name: string;
  video_url: string;
  use_video_timestamps: boolean;
  event_callback: {
    url: string;
    send_overview: boolean;
    overview_jpeg_compression: number;
    with_annotation: boolean;
  } | null;
  person_detection_parameters: {
    min_height_px: number | null;
    max_height_px: number | null;
    roi: polygon | null;
    confidence_threshold: number;
    period_ms: number | null;
    overlap_threshold: number;
  };
}

const initialState: CamerasState = {
  cameras: [],
};

export const camerasSlice = createSlice({
  name: "cameras",
  initialState,
  reducers: {
    setCameras: (state, { payload }) => {
      state.cameras = payload;
    },
    addToCameras: (state, { payload }) => {
      state.cameras = [...state.cameras, payload];
    },
  },
});

export const { setCameras, addToCameras } = camerasSlice.actions;

export default camerasSlice.reducer;
