import { Point } from "./EditROIProvider";

export const transferToSVGCoords = (
  x: number,
  y: number,
  svgRef: React.RefObject<SVGSVGElement>
) => {
  let p = svgRef.current!.createSVGPoint();
  p.x = x;
  p.y = y;
  let ctm = (svgRef.current as any).getScreenCTM().inverse();
  p = p.matrixTransform(ctm);
  return p;
};

export const createPolygonCoords = (points: Point[]) => {
  let str = "";
  for (const point of points) {
    str += `${point[0]}, ${point[1]} `;
  }
  return str;
};
