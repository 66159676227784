import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertPoint,
  Point,
  useEditROIProvider,
} from "../../EditROI/EditROIProvider";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { CameraType, setCameras } from "../../store/slices/CamerasSlice";
import { deleteCam as deleteCamReq, patchCam } from "../../utils/api";
import { delete_cam, WEBSOCKET_URL } from "../../utils/constants";
import useAllCamerasPage from "../AllCamerasPage/useAllCamerasPage";
import { useSelectedCamPageProvider } from "./SelectedCamProvider";
import { useConfirm } from "../../Confirm/ConfirmProvider";

export type bbox = [number, number, number, number];
type Result = {
  id: string;
  camera_id: string;
  timestamp: string;
  persons: {
    bbox: bbox;
    score: number;
  }[];
};

type SubscriptionData = {
  subscription_type: string;
  camera_id: string;
  results: Result;
};

const ws = new WebSocket(WEBSOCKET_URL);

ws.onopen = () => console.log("connected");

const useSelectedCamPage = () => {
  // states
  const [loading, setLoading] = useState(false);
  const [editROI, setEditROI] = useState(false);
  const [selectedCam, setSelectedCam] = useState<CameraType | null>(null);
  const [resultBboxes, setResultBboxes] = useState<bbox[]>([]);
  const [result_id, setResult_id] = useState("");

  // custom hooks
  const { points, setPoints, setCurrentPointIndex } = useEditROIProvider();
  const { getAllCameras } = useAllCamerasPage();
  const { isConfirmed, setConfirm, confirm } = useConfirm();
  const { setShowEditForm, setError, error } = useSelectedCamPageProvider();
  const { cameras } = useAppSelector((state) => state.cameras);

  // module hooks
  const dispatch = useAppDispatch();
  const { c_id } = useParams();
  const navigate = useNavigate();

  // utils
  const deleteCam = async () => {
    setConfirm({
      ...confirm,
      loading: true,
      isOpen: true,
      prompt: delete_cam,
    });
    try {
      await deleteCamReq(c_id!);
      const updatedCameras = cameras.filter((cam) => cam.id !== c_id);
      dispatch(setCameras(updatedCameras));
      navigate("/");
    } catch (e: any) {
      setError(e.response.data?.message || e.response.status);
    } finally {
      setConfirm({
        ...confirm,
        isOpen: false,
        prompt: delete_cam,
      });
    }
  };

  // click listeners
  const deleteCamClick = async () => {
    const confirmed = await isConfirmed(delete_cam);
    if (confirmed) {
      await deleteCam();
    } else {
      setConfirm({
        ...confirm,
        isOpen: false,
        prompt: delete_cam,
      });
    }
  };

  const editCamClick = () => {
    setShowEditForm(true);
  };
  const closeROIEditClick = () => {
    setEditROI(false);
    setPoints({});
    setCurrentPointIndex(0);
    resubscribeToCam(false);
  };
  const saveROIClick = async () => {
    startRequest();
    resubscribeToCam(false);
    const convertedPoints = convertPoint(points);
    try {
      const { data } = await patchCam(c_id!, {
        person_detection_parameters: { roi: convertedPoints },
      });
      const updatedCameras = cameras.map((cam) => {
        if (cam.id === data.id) {
          console.log(convertedPoints);
          console.log(data.person_detection_parameters.roi);
          return data;
        }
        return cam;
      });
      dispatch(setCameras(updatedCameras));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setEditROI(false);
    }
  };
  const deleteROIClick = async () => {
    startRequest();
    try {
      const { data: updatedCam } = await patchCam(c_id!, {
        person_detection_parameters: { roi: null },
      });
      const updatedCameras = cameras.map((cam) => {
        if (cam.id === updatedCam.id) {
          return updatedCam;
        }
        return cam;
      });
      setPoints({});
      setCurrentPointIndex(0);
      dispatch(setCameras(updatedCameras));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setEditROI(false);
    }
  };
  const editROIClick = () => {
    setEditROI(true);
    resubscribeToCam(true);
    const curCam = cameras.filter((cam) => cam.id === c_id)[0];
    if (curCam && curCam.person_detection_parameters?.roi) {
      const { roi } = curCam.person_detection_parameters;
      const points: { [key: string]: Point } = {};
      for (let i = 0; i < roi.length; i++) {
        points[i] = roi[i];
      }
      setPoints(points);
      setCurrentPointIndex(roi.length);
    }
  };
  const toMainPageClick = () => {
    navigate("/");
  };

  // utils
  const startRequest = () => {
    setError("");
    setLoading(true);
  };
  function subscribeToCam(
    camId: string,
    decrease_detection_period: boolean = false
  ) {
    // wait until websocket opens connection
    if (ws.readyState !== 1) {
      setTimeout(() => subscribeToCam(camId), 1000);
      return;
    }
    const subsribeParams = {
      action: "subscribe",
      subscription_type: "results",
      cameras: [
        {
          id: camId,
          decrease_detection_period,
        },
      ],
    };
    ws.send(JSON.stringify(subsribeParams));
    ws.onerror = (e) => console.log(e);
    ws.onmessage = (e) => {
      const data: SubscriptionData = JSON.parse(e.data);
      if (data.subscription_type === "results") {
        const bboxesList = [];
        if (!data.results) return;
        for (const person of data.results.persons) {
          bboxesList.push(person.bbox);
        }
        setResult_id(data.results.id);
        setResultBboxes(bboxesList);
      }
    };
  }
  function unsubscribeFromCam(camId: string) {
    if (ws.readyState !== 1) return;
    const unsubsribeParams = {
      action: "unsubscribe",
      subscription_type: "results",
      cameras: [camId],
    };
    ws.send(JSON.stringify(unsubsribeParams));
    ws.onerror = (e) => console.log(e);
    ws.onmessage = (e) => {
      console.log(e);
    };
  }
  function resubscribeToCam(decrease_detection_period: boolean) {
    unsubscribeFromCam(c_id!);
    subscribeToCam(c_id!, decrease_detection_period);
  }

  useEffect(() => {
    if (!c_id) return;
    const cam = cameras.filter((cam) => cam.id === c_id)[0];
    setSelectedCam(cam);
  }, [c_id, cameras]);

  return {
    resultBboxes,
    result_id,
    c_id,
    editROI,
    loading,
    points,
    selectedCam,
    cameras,
    error,
    editROIClick,
    saveROIClick,
    deleteROIClick,
    closeROIEditClick,
    editCamClick,
    deleteCamClick,
    toMainPageClick,
    getAllCameras,
    subscribeToCam,
    unsubscribeFromCam,
  };
};

export default useSelectedCamPage;
