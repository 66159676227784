import { CircularProgress, Alert } from "@mui/material";
import AddCameraForm from "../../Forms/AddCameraForm";
import CamCard from "../../CamCard";
import styles from "./AllCamerasPage.module.scss";
import useAllCamerasPage from "./useAllCamerasPage";
import { AllCameraPageProvider } from "./AllCameraPageProvider";
import { useEffect } from "react";

const AllCamerasPage = () => {
  const { addCamClick, getAllCameras, loading, error, cameras } =
    useAllCamerasPage();

  useEffect(() => {
    getAllCameras();
  }, []);

  if (error) {
    return (
      <div className={styles["loading-container"]}>
        <Alert severity="error">{error}</Alert>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={styles["loading-container"]}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles["container"]}>
      <div onClick={addCamClick} className={styles["add-camera"]}>
        <div>Добавить камеру</div>
        <p>+</p>
      </div>
      {cameras.map((cam) => {
        return <CamCard key={cam.id} camera={cam} />;
      })}
      <AddCameraForm />
    </div>
  );
};

const AllCamerasPageWithContext = () => {
  return (
    <AllCameraPageProvider>
      <AllCamerasPage />
    </AllCameraPageProvider>
  );
};

export default AllCamerasPageWithContext;
