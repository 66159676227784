import axios, { AxiosPromise } from "axios";
import { CameraType } from "../store/slices/CamerasSlice";
import { API_URL } from "./constants";

// GET /v1/cameras
export const getAllCameras: () => AxiosPromise<{
  cameras: CameraType[];
}> = () => {
  return axios({
    method: "GET",
    url: `${API_URL}/cameras`,
    headers: {},
  });
};

// GET /v1/cameras/:c_id/frame
export const getCamFram: (
  c_id: string
) => AxiosPromise<{ data: CameraType }> = (c_id) => {
  return axios({
    method: "GET",
    url: `${API_URL}/cameras/${c_id}/frame`,
    headers: {},
  });
};

// POST /v1/cameras
export const createCam: (
  name: string,
  video_url: string
) => AxiosPromise<{}> = (name, video_url) => {
  return axios({
    method: "POST",
    url: `${API_URL}/cameras`,
    data: { name, video_url },
  });
};

// DELETE /v1/cameras/:c_id
export const deleteCam: (c_id: string) => AxiosPromise<CameraType> = (
  c_id: string
) => {
  return axios({
    method: "DELETE",
    url: `${API_URL}/cameras/${c_id}`,
  });
};

// PATCH /v1/cameras/:c_id
export const patchCam: (
  c_id: string,
  patchedFields: {}
) => AxiosPromise<CameraType> = (c_id, patchedFields) => {
  return axios({
    method: "PATCH",
    url: `${API_URL}/cameras/${c_id}`,
    data: patchedFields,
  });
};
