import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { createCam as createCamReq } from "../../utils/api";
import { useAppDispatch } from "../../store/hooks";
import { addToCameras } from "../../store/slices/CamerasSlice";
import { useAllCameraPageProvider } from "../../pages/AllCamerasPage/AllCameraPageProvider";

const useAddCameraForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const startRequest = () => {
    setError("");
    setLoading(true);
  };
  const dispatch = useAppDispatch();
  const { showAddForm, setShowAddForm } = useAllCameraPageProvider();
  const handleClose = () => {
    setShowAddForm(false);
  };

  const createCam = async (name: string, video_url: string) => {
    startRequest();
    try {
      const { data } = await createCamReq(name, video_url);
      dispatch(addToCameras(data));
      handleClose();
    } catch (e: any) {
      setError(e.response.data?.message || e.response.status);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: { name: "", video_url: "" },
    onSubmit: (values) => {
      const { name, video_url } = values;
      createCam(name, video_url);
    },
  });
  return { formik, loading, handleClose, showAddForm, error };
};

export default useAddCameraForm;
