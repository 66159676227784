import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelectedCamPageProvider } from "../../pages/SelectedCamPage/SelectedCamProvider";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { CameraType, setCameras } from "../../store/slices/CamerasSlice";
import { patchCam as patchCamReq } from "../../utils/api";
import { changedFields } from "../../utils/functions";

const fakeCamera: CameraType = {
  id: "",
  name: "",
  video_url: "",
  use_video_timestamps: false,
  event_callback: null,
  person_detection_parameters: {
    min_height_px: null,
    max_height_px: null,
    confidence_threshold: 0.8,
    roi: null,
    overlap_threshold: 0.9,
    period_ms: null,
  },
};

const modifyEventFields = (
  sortedFields: { [key: string]: any },
  initialEventState: null | {},
  eventCallbackIsOn: boolean,
  allCurrentFields: { [key: string]: any }
) => {
  const copy: { [key: string]: any } = Object.assign({}, { ...sortedFields });
  if (!eventCallbackIsOn) {
    if (initialEventState === null) {
      // поле не поменялось
      delete copy["event_callback"];
    } else {
      copy["event_callback"] = null;
    }
  }
  if (eventCallbackIsOn) {
    // поле поменялось
    if (initialEventState === null) {
      // текущие значения полей event_callback
      const { url, send_overview, overview_jpeg_compression, with_annotation } =
        allCurrentFields;
      copy["event_callback"] = {
        url,
        send_overview,
        overview_jpeg_compression,
        with_annotation,
      };
    }
  }
  return copy;
};

const sortFields = (fields: { [key: string]: any }) => {
  if (Object.keys(fields).length === 0) return {};
  enum event_callback_fields {
    url,
    send_overview,
    overview_jpeg_compression,
    with_annotation,
  }
  enum person_detection_parameters_fields {
    min_height_px,
    max_height_px,
    confidence_threshold,
    roi,
    overlap_threshold,
    period_ms,
  }
  const res: { [key: string]: any } = {};
  const event_callback: { [key: string]: any } = {};
  const person_detection_parameters: { [key: string]: any } = {};
  let event_callback_has_been_changed = false;
  let person_detection_parameters_has_been_changed = false;
  for (const key of Object.keys(fields)) {
    if (key in event_callback_fields) {
      event_callback[key] = fields[key];
      event_callback_has_been_changed = true;
    } else if (key in person_detection_parameters_fields) {
      person_detection_parameters[key] = fields[key];
      person_detection_parameters_has_been_changed = true;
    } else {
      res[key] = fields[key];
    }
  }
  if (event_callback_has_been_changed) {
    res["event_callback"] = event_callback;
  }
  if (person_detection_parameters_has_been_changed) {
    res["person_detection_parameters"] = person_detection_parameters;
  }
  return res;
};

const useEditCamForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const startRequest = () => {
    setError("");
    setLoading(true);
  };
  const { showEditForm, setShowEditForm } = useSelectedCamPageProvider();
  const dispatch = useAppDispatch();
  const { c_id } = useParams();
  const { cameras } = useAppSelector((state) => state.cameras);
  const camera = cameras.filter((cam) => cam.id === c_id)[0];
  const {
    name,
    video_url,
    use_video_timestamps,
    event_callback,
    person_detection_parameters,
  } = camera || fakeCamera;
  const { url, send_overview, overview_jpeg_compression, with_annotation } =
    event_callback || {
      url: "",
      send_overview: true,
      overview_jpeg_compression: 70,
      with_annotation: false,
    };
  const {
    min_height_px,
    max_height_px,
    confidence_threshold,
    roi,
    overlap_threshold,
    period_ms,
  } = person_detection_parameters;
  const [eventCallbackIsOn, setEventCallbackIsOn] = useState(
    event_callback !== null
  );
  const handleClose = () => {
    setShowEditForm(false);
  };

  const patchCam = async (sortedFields: {}) => {
    startRequest();
    try {
      const { data: patchedCam } = await patchCamReq(camera.id, sortedFields);
      const updatedCameras = cameras.map((cam) => {
        if (cam.id === patchedCam.id) {
          return patchedCam;
        }
        return cam;
      });
      dispatch(setCameras(updatedCameras));
      handleClose();
    } catch (e: any) {
      const message = e.response.data?.message || e.response.status;
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name,
      video_url,
      use_video_timestamps,
      url,
      send_overview,
      overview_jpeg_compression,
      with_annotation,
      min_height_px,
      max_height_px,
      confidence_threshold,
      roi,
      overlap_threshold,
      period_ms,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const oldState = {
        name,
        video_url,
        use_video_timestamps,
        min_height_px,
        max_height_px,
        confidence_threshold,
        roi,
        overlap_threshold,
        period_ms,
        url,
        send_overview,
        overview_jpeg_compression,
        with_annotation,
      };
      const changed = changedFields(oldState, values);
      let sortedFields = sortFields(changed);
      sortedFields = modifyEventFields(
        sortedFields,
        event_callback,
        eventCallbackIsOn,
        values
      );
      patchCam(sortedFields);
    },
  });

  useEffect(() => {
    if (camera) {
      setEventCallbackIsOn(camera.event_callback !== null);
    }
  }, [camera]);

  return {
    showEditForm,
    setShowEditForm,
    handleClose,
    formik,
    eventCallbackIsOn,
    setEventCallbackIsOn,
    loading,
    error,
  };
};

export default useEditCamForm;
