import React, { useState } from "react";

export type Point = [number, number];
type Props = {
  children: React.ReactNode;
};

type Default = {
  points: { [key: string]: Point };
  setPoints: React.Dispatch<
    React.SetStateAction<{
      [key: string]: Point;
    }>
  >;
  currentPointIndex: number;
  setCurrentPointIndex: React.Dispatch<React.SetStateAction<number>>;
};

const EditROIContext = React.createContext({} as Default);

function EditROIProvider({ children }: Props) {
  const [points, setPoints] = useState<{ [key: string]: Point }>({});
  const [currentPointIndex, setCurrentPointIndex] = useState(
    Object.keys(points).length
  );
  return (
    <EditROIContext.Provider
      value={{ points, setPoints, currentPointIndex, setCurrentPointIndex }}
    >
      {children}
    </EditROIContext.Provider>
  );
}

function useEditROIProvider() {
  const context = React.useContext(EditROIContext);
  if (context === undefined) {
    throw new Error("useEditROIProvider must be used within a EditROIProvider");
  }
  return context;
}

export const convertPoint = (obj: { [key: string]: Point }) => {
  const arr = [];
  for (const val of Object.values(obj)) {
    arr.push(val);
  }
  return arr;
};

export { EditROIProvider, useEditROIProvider };
