import { LoadingButton } from "@mui/lab";
import { Alert, Button } from "@mui/material";
import { useEffect } from "react";
import EditROI from "../../EditROI";
import EditCamForm from "../../Forms/EditCamForm";
import styles from "./SelectedCamPage.module.scss";
import { SelectedCamPageProvider } from "./SelectedCamProvider";
import useSelectedCamPage from "./useSelectedCamPage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CamImg from "../../CamImg";
import { formatPluralNumeral } from "../../utils/functions";

const SelectedCamPage = () => {
  const {
    resultBboxes,
    result_id,
    c_id,
    editROI,
    loading,
    points,
    selectedCam,
    cameras,
    error,
    editROIClick,
    saveROIClick,
    deleteROIClick,
    closeROIEditClick,
    editCamClick,
    deleteCamClick,
    toMainPageClick,
    getAllCameras,
    subscribeToCam,
    unsubscribeFromCam,
  } = useSelectedCamPage();

  useEffect(() => {
    c_id && subscribeToCam(c_id);
    if (cameras.length === 0) {
      getAllCameras();
    }
    return () => {
      c_id && unsubscribeFromCam(c_id);
    };
  }, [c_id]);

  return (
    <div className={styles["page-container"]}>
      <div className={styles["component-container"]}>
        <Button startIcon={<ArrowBackIcon />} onClick={toMainPageClick}>
          На главную
        </Button>
        {c_id && (
          <div className={styles["img-container"]}>
            <CamImg
              c_id={c_id}
              display_polygon={editROI ? 0 : 1}
              style={{ minWidth: "70vw" }}
              bboxlist={resultBboxes}
              result_id={result_id}
            />
            {editROI && <EditROI c_id={c_id} />}
          </div>
        )}
        <div className={styles["down-section"]}>
          <div>
            {selectedCam && <p>{selectedCam.name}</p>}
            <p>Найдено: {formatPluralNumeral(resultBboxes.length)}</p>
          </div>
          {error && <Alert severity="error">{error}</Alert>}
          {editROI ? (
            <>
              <div className={styles["alert-container"]}>
                <Alert severity="warning">
                  Точки должны добавляться по часовой стрелке
                </Alert>
                <Alert severity="info">
                  Нажатие правой кнопки мыши удаляет точку
                </Alert>
              </div>
              <div className={styles["btns-container"]}>
                <LoadingButton
                  loading={loading}
                  onClick={saveROIClick}
                  variant="contained"
                  disabled={Object.keys(points).length < 3}
                >
                  Сохранить ROI
                </LoadingButton>
                <Button
                  onClick={deleteROIClick}
                  variant="outlined"
                  color="error"
                  disabled={loading || Object.keys(points).length === 0}
                >
                  Удалить ROI
                </Button>
                <Button
                  onClick={closeROIEditClick}
                  variant="outlined"
                  disabled={loading}
                >
                  Отмена
                </Button>
              </div>
            </>
          ) : (
            <div className={styles["btns-container"]}>
              <Button
                onClick={editROIClick}
                variant="outlined"
                disabled={!!error}
              >
                {selectedCam?.person_detection_parameters.roi
                  ? "Редактировать "
                  : "Добавить "}
                ROI
              </Button>
              <Button onClick={editCamClick} variant="outlined">
                Редактировать камеру
              </Button>
              <Button onClick={deleteCamClick} variant="outlined" color="error">
                Удалить камеру
              </Button>
            </div>
          )}
        </div>
      </div>
      <EditCamForm />
    </div>
  );
};

const SelectedCamPageWithContext = () => {
  return (
    <SelectedCamPageProvider>
      <SelectedCamPage />
    </SelectedCamPageProvider>
  );
};

export default SelectedCamPageWithContext;
