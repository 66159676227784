import { useState } from "react";
import { useAppSelector } from "../store/hooks";

const useCamImg = () => {
  // state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [imgParams, setImgParams] = useState({ w: 0, h: 0 });
  const [imgHref, setImgHref] = useState("");

  // module hooks
  const { cameras } = useAppSelector((state) => state.cameras);

  // utils
  const startRequest = () => {
    setError("");
    setLoading(true);
  };
  const setImageByUrl = async (url: string, imgRef: any, tries?: number) => {
    const TRY_AGAIN_TIMEOUT = 5000; // ms
    const MAX_GET_IMG_TRIES = 3;
    startRequest();
    await fetch(url)
      .then(async function (response) {
        if (!response.ok) {
          imgRef.src = "none";
          throw new Error("Ошибка загрузки кадра!");
        }
        return response.blob();
      })
      .then(function (myBlob) {
        var objectURL = URL.createObjectURL(myBlob);
        imgRef.src = objectURL;
        const image = new Image();
        image.src = objectURL;
        image.onload = (e) => {
          setImgHref(image.src);
          setImgParams({ w: image.width, h: image.height });
        };
      })
      .catch(async (e: any) => {
        setError(e.message);
        // try again every TRY_AGAIN_TIMEOUT sec maxTries times
        if (tries !== undefined && tries < MAX_GET_IMG_TRIES) {
          setTimeout(async () => {
            await setImageByUrl(url, imgRef, ++tries!);
          }, TRY_AGAIN_TIMEOUT);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    imgHref,
    imgParams,
    loading,
    cameras,
    error,
    setImageByUrl,
  };
};

export default useCamImg;
