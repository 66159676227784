import React, { createContext, useContext, useState } from "react";

export const ConfirmContext = createContext({
  confirm: {
    prompt: "",
    isOpen: false,
    proceed: null,
    cancel: null,
    loading: false,
  },
  setConfirm: (val: any) => {},
});

const ConfirmContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [confirm, setConfirm] = useState({
    prompt: "",
    isOpen: false,
    proceed: null,
    cancel: null,
    loading: false,
  });

  return (
    <ConfirmContext.Provider value={{ confirm, setConfirm }}>
      {children}
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {
  const { confirm, setConfirm } = useContext(ConfirmContext);

  const isConfirmed = (prompt: any) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        prompt,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
        loading: false,
      });
    });
    return promise.then(
      () => {
        return true;
      },
      () => {
        return false;
      }
    );
  };
  return {
    ...confirm,
    isConfirmed,
    setConfirm,
    confirm,
  };
};

export default ConfirmContextProvider;
