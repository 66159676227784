import React, { useCallback, useState } from "react";
import { Point } from "./EditROIProvider";
import { transferToSVGCoords } from "./functions";

type PointProps = {
  p: Point;
  id: string;
  svgRef: React.RefObject<SVGSVGElement>;
  divRef: React.RefObject<HTMLDivElement>;
  setPoints: React.Dispatch<React.SetStateAction<{ [key: string]: Point }>>;
};

const EditPoint = ({ p, setPoints, id, svgRef, divRef }: PointProps) => {
  const [isDragging, setIsDragging] = useState(false);

  const ref = useCallback((node: SVGCircleElement) => {
    if (node !== null) {
      node.addEventListener("mousedown", (e) => {
        e.stopPropagation();
        if (e.button === 2) return; // right button click
        divRef.current?.addEventListener("mousemove", drag);
        setIsDragging(true);
      });
      node.addEventListener("mouseup", (e) => {
        divRef.current?.removeEventListener("mousemove", drag);
        setIsDragging(false);
      });
    }
  }, []);

  function drag(e: MouseEvent) {
    e.stopPropagation();
    setPoints((prev) => {
      const objCopy = Object.assign({}, prev);
      const { x, y } = transferToSVGCoords(e.clientX, e.clientY, svgRef);
      objCopy[id] = [x, y];
      return objCopy;
    });
  }

  const handleRightClick = () => {
    setPoints((prev) => {
      const objCopy = Object.assign({}, prev);
      delete objCopy[id];
      return objCopy;
    });
  };
  return (
    <circle
      ref={ref}
      cx={p[0]}
      cy={p[1]}
      r={10}
      fill="blue"
      onContextMenu={(e) => {
        e.preventDefault();
        handleRightClick();
      }}
      stroke={"black"}
      strokeOpacity={0}
      strokeWidth={50} // большая ширина, чтобы можно было щелкать рядом с точкой
      style={{ cursor: isDragging ? "grabbing" : "grab" }}
    />
  );
};

export default EditPoint;
