type fields = { [key: string]: any };

export const changedFields = (oldObj: fields, newObj: fields) => {
  const updatedFields: fields = {};
  for (const key of Object.keys(oldObj)) {
    if (oldObj[key] !== newObj[key]) {
      updatedFields[key] = newObj[key];
    }
  }
  return updatedFields;
};

export const formatPluralNumeral = (value: number) => {
  const nouns = ["человек", "человека", "человек"];
  let p = 0;
  if (value % 10 == 1 && value % 100 != 11) {
    p = 0;
  } else if (
    2 <= value % 10 &&
    value % 10 <= 4 &&
    (value % 100 < 10 || value % 100 >= 20)
  ) {
    p = 1;
  } else {
    p = 2;
  }
  return `${value} ${nouns[p]}`;
};

export const cutEmptyRelativePath = () => {
  const { API_URL } = window;
  if (API_URL[API_URL.length - 1] === "/") return API_URL.slice(0, -1);
  return API_URL;
};

export const generateWebSocketURL = () => {
  const URL = cutEmptyRelativePath();
  let websocketURL = URL;
  if (URL[0] === "/") {
    // the URL is relative
    websocketURL = window.location.origin + URL;
  }
  // the URL is absolute
  if (websocketURL.search(/https:\/\//) !== -1) {
    websocketURL = websocketURL.replace(/https:\/\//, "wss://");
  } else if (websocketURL.search(/http:\/\//) !== -1) {
    websocketURL = websocketURL.replace(/http:\/\//, "ws://");
  } else {
    throw new Error("Wrong API_URL");
  }
  return websocketURL + "/subscribe";
};
