import {
  Alert,
  Backdrop,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Paper,
  Switch,
  TextField,
} from "@mui/material";
import ClickOutside from "../../ClickOutside";
import useEditCamForm from "./useEditCamForm";
import styles from "./EditCamForm.module.scss";
import { LoadingButton } from "@mui/lab";

const EditCamForm = () => {
  const {
    showEditForm,
    handleClose,
    formik,
    eventCallbackIsOn,
    setEventCallbackIsOn,
    loading,
    error,
  } = useEditCamForm();
  return (
    <Backdrop open={showEditForm}>
      <ClickOutside foo={handleClose}>
        <Paper
          className={styles["paper"]}
          component="form"
          onSubmit={formik.handleSubmit}
        >
          {error && <Alert severity="error">{error}</Alert>}
          <div className={styles["container"]}>
            <div className={styles["section"]}>
              <h3>Основные</h3>
              <TextField
                required
                label="Название камеры"
                id="name"
                name="name"
                value={formik.values["name"]}
                onChange={formik.handleChange}
                fullWidth
              />
              <TextField
                required
                label="RTSP URL"
                id="video_url"
                name="video_url"
                value={formik.values["video_url"]}
                onChange={formik.handleChange}
                fullWidth
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={!!formik.values["use_video_timestamps"]}
                    onChange={(e, checked) =>
                      formik.setFieldValue("use_video_timestamps", checked)
                    }
                    id="use_video_timestamps"
                    name="use_video_timestamps"
                  />
                }
                label="Использовать временные метки из видео"
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <h3>Ответный запрос</h3>
                <Checkbox
                  checked={eventCallbackIsOn}
                  onChange={(e, checked) => setEventCallbackIsOn(checked)}
                />
              </div>

              {!formik.values["url"] && eventCallbackIsOn && (
                <Alert severity="warning">
                  url запроса является обязательным!
                </Alert>
              )}
              <TextField
                label="url запроса"
                id="url"
                name="url"
                value={formik.values["url"]}
                onChange={formik.handleChange}
                disabled={!eventCallbackIsOn}
                required={eventCallbackIsOn}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={!!formik.values["send_overview"]}
                    onChange={(e, checked) =>
                      formik.setFieldValue("send_overview", checked)
                    }
                    id="send_overview"
                    name="send_overview"
                  />
                }
                label="Отсылать кадр"
                disabled={!eventCallbackIsOn}
              />
              <TextField
                type="number"
                label="Степень JPEG сжатия кадра"
                id="overview_jpeg_compression"
                name="overview_jpeg_compression"
                value={formik.values["overview_jpeg_compression"] || 0}
                onChange={(e) => {
                  const value = +e.target.value;
                  formik.setFieldValue(
                    "overview_jpeg_compression",
                    value || null
                  );
                }}
                inputProps={{ min: 0, max: 100 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                disabled={!eventCallbackIsOn || !formik.values["send_overview"]}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={!!formik.values["with_annotation"]}
                    onChange={(e, checked) =>
                      formik.setFieldValue("with_annotation", checked)
                    }
                    id="with_annotation"
                    name="with_annotation"
                  />
                }
                label="Отображать аннотации на отправляемых кадрах"
                disabled={!eventCallbackIsOn || !formik.values["send_overview"]}
              />
            </div>
            <div className={styles["section"]}>
              <h3>Параметры детекции</h3>
              <TextField
                type="number"
                label="Минимальная высота силуэтов"
                id="min_height_px"
                name="min_height_px"
                value={formik.values["min_height_px"] || 0}
                onChange={(e) => {
                  const value = +e.target.value;
                  if (value < 0) return;
                  formik.setFieldValue("min_height_px", value || null);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">px</InputAdornment>
                  ),
                }}
                helperText={
                  !formik.values["min_height_px"] ? "Ограничение отключено" : ""
                }
                sx={{ mb: 2 }}
                fullWidth
              />
              <TextField
                type="number"
                label="Максимальная высота силуэтов"
                id="max_height_px"
                value={formik.values["max_height_px"] || 0}
                onChange={(e) => {
                  const value = +e.target.value;
                  if (value < 0) return;
                  formik.setFieldValue("max_height_px", value || null);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">px</InputAdornment>
                  ),
                }}
                helperText={
                  !formik.values["max_height_px"] ? "Ограничение отключено" : ""
                }
                sx={{ mb: 2 }}
                fullWidth
              />
              <TextField
                type="number"
                label="Порог уверенности детектора"
                id="confidence_threshold"
                name="confidence_threshold"
                value={formik.values["confidence_threshold"] || 0}
                onChange={(e) => {
                  const value = +e.target.value;
                  formik.setFieldValue("confidence_threshold", value);
                }}
                inputProps={{ min: 0.1, max: 1, step: 0.01 }}
                fullWidth
              />
              <TextField
                type="number"
                label="Доля детекции внутри ROI"
                id="overlap_threshold"
                name="overlap_threshold"
                value={parseInt(formik.values["overlap_threshold"] * 100 + "")}
                onChange={(e) => {
                  const value = +e.target.value / 100;
                  formik.setFieldValue("overlap_threshold", value);
                }}
                inputProps={{ min: 0, max: 100 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                fullWidth
              />
              <TextField
                type="number"
                label="Периодичность запуска детектора"
                id="period_ms"
                name="period_ms"
                value={formik.values["period_ms"] || 0}
                onChange={(e) => {
                  const value = +e.target.value;
                  if (value < 0) return;
                  formik.setFieldValue("period_ms", value || null);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">мс</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </div>
          </div>
          <LoadingButton loading={loading} type="submit" variant="contained">
            Сохранить
          </LoadingButton>
        </Paper>
      </ClickOutside>
    </Backdrop>
  );
};

export default EditCamForm;
