import { CameraType } from "../store/slices/CamerasSlice";
import styles from "./CamCard.module.scss";
import useCamCard from "./useCamCard";
import CamImg from "../CamImg";

type CamCardProps = {
  camera: CameraType;
};

const CamCard = ({ camera }: CamCardProps) => {
  const { deleteCamClick, camClick } = useCamCard(camera);

  return (
    <div className={styles["container"]}>
      <span
        className={styles["delete-button"]}
        onClick={(e) => {
          e.stopPropagation();
          deleteCamClick();
        }}
      />
      <div className={styles["img-container"]} onClick={camClick}>
        <CamImg c_id={camera.id} display_polygon={1} />
      </div>
      <p>{camera.name}</p>
    </div>
  );
};

export default CamCard;
