import { useCallback, useEffect, useRef } from "react";
import useEditROI from "./useEditROI";
import styles from "./EditROI.module.scss";
import { useEditROIProvider } from "./EditROIProvider";
import { createPolygonCoords, transferToSVGCoords } from "./functions";
import EditPoint from "./EditPoint";
import { API_URL } from "../utils/constants";

type EditROIProps = {
  c_id: string;
};

const EditROI = ({ c_id }: EditROIProps) => {
  const { imgHref, imgParams, setImageByUrl } = useEditROI();
  const { points, setPoints, currentPointIndex, setCurrentPointIndex } =
    useEditROIProvider();

  // refs
  const svgRef = useRef<SVGSVGElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  // functions
  const createPoint = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { x, y } = transferToSVGCoords(e.clientX, e.clientY, svgRef);
    setPoints((prev) => {
      const objCopy = Object.assign({}, prev);
      objCopy[currentPointIndex] = [x, y];
      return objCopy;
    });
    setCurrentPointIndex((prev) => ++prev);
  };

  const imageRef = useCallback(async (node: SVGImageElement) => {
    if (node !== null) {
      const url = `${API_URL}/cameras/${c_id}/frame`;
      await setImageByUrl(url, node);
    }
  }, []);

  return (
    <div
      className={styles["container"]}
      ref={ref}
      onMouseDown={(e) => createPoint(e)}
    >
      <svg viewBox={`0 0 ${imgParams.w} ${imgParams.h}`} ref={svgRef}>
        <image href={imgHref} display="none" ref={imageRef} />
        {Object.values(points).length > 2 && (
          <polygon
            points={createPolygonCoords(Object.values(points))}
            fillOpacity={0}
            stroke="blue"
            strokeWidth={4}
          />
        )}
        {Object.entries(points).map(([id, p]) => (
          <EditPoint
            p={p}
            key={id}
            id={id}
            setPoints={setPoints}
            svgRef={svgRef}
            divRef={ref}
          />
        ))}
      </svg>
    </div>
  );
};

export default EditROI;
