import { useConfirm } from "./ConfirmProvider";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Confirm = () => {
  const { prompt = "", isOpen = true, proceed, cancel, loading } = useConfirm();

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Подтвердите действие</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 14, color: "#000" }}>
          {prompt}
        </DialogContentText>
        <div style={{ display: "flex", gap: 10, marginTop: 10 }}>
          <LoadingButton
            loading={loading}
            onClick={proceed!}
            variant="outlined"
          >
            Да
          </LoadingButton>
          <Button onClick={cancel!} variant="contained">
            Нет
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Confirm;
