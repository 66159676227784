import { cutEmptyRelativePath, generateWebSocketURL } from "./functions";

declare global {
  interface Window {
    API_URL: string;
  }
}

export const delete_cam = "Вы уверены, что хотите удалить камеру?";

export const API_URL = cutEmptyRelativePath();

export const WEBSOCKET_URL = generateWebSocketURL();
