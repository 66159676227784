import { useNavigate } from "react-router-dom";
import { useConfirm } from "../Confirm/ConfirmProvider";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { CameraType, setCameras } from "../store/slices/CamerasSlice";
import { deleteCam as deleteCamReq } from "../utils/api";
import { delete_cam } from "../utils/constants";

const useCamCard = (camera: CameraType) => {
  // custom hooks
  const { isConfirmed, setConfirm, confirm } = useConfirm();

  // module hooks
  const { cameras } = useAppSelector((state) => state.cameras);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // utils
  const deleteCam = async (cam_id: string) => {
    setConfirm({
      ...confirm,
      loading: true,
      isOpen: true,
      prompt: delete_cam,
    });
    try {
      await deleteCamReq(cam_id);
      const updatedCameras = cameras.filter((cam) => cam.id !== cam_id);
      dispatch(setCameras(updatedCameras));
    } catch (e) {
      console.log(e);
    } finally {
      setConfirm({
        ...confirm,
        isOpen: false,
        prompt: delete_cam,
      });
    }
  };

  // click listeners
  const deleteCamClick = async () => {
    const confirmed = await isConfirmed(delete_cam);
    if (confirmed) {
      deleteCam(camera.id);
    } else {
      setConfirm({
        ...confirm,
        isOpen: false,
        prompt: delete_cam,
      });
    }
  };
  const camClick = () => {
    navigate(`/selected/${camera.id}`);
  };

  return {
    deleteCamClick,
    camClick,
  };
};

export default useCamCard;
