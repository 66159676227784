import { useState } from "react";

const useCamCard = () => {
  // state
  const [imgParams, setImgParams] = useState({ w: 0, h: 0 });
  const [imgHref, setImgHref] = useState("");

  // utils
  const setImageByUrl = async (url: string, imgRef: any) => {
    await fetch(url)
      .then(async function (response) {
        if (!response.ok) {
          imgRef.src = "none";
          throw new Error("Ошибка загрузки кадра!");
        }
        return response.blob();
      })
      .then(function (myBlob) {
        var objectURL = URL.createObjectURL(myBlob);
        imgRef.src = objectURL;
        const image = new Image();
        image.src = objectURL;
        image.onload = (e) => {
          setImgHref(image.src);
          setImgParams({ w: image.width, h: image.height });
        };
      })
      .catch(async (e: any) => {
        console.log(e);
      });
  };

  return {
    imgHref,
    imgParams,
    setImageByUrl,
  };
};

export default useCamCard;
