import React, { useState } from "react";

type Props = {
  children: React.ReactNode;
};

const AllCameraPageContext = React.createContext({
  showAddForm: false,
  setShowAddForm: (val: boolean) => {},
});

function AllCameraPageProvider({ children }: Props) {
  const [showAddForm, setShowAddForm] = useState(false);
  return (
    <AllCameraPageContext.Provider value={{ showAddForm, setShowAddForm }}>
      {children}
    </AllCameraPageContext.Provider>
  );
}

function useAllCameraPageProvider() {
  const context = React.useContext(AllCameraPageContext);
  if (context === undefined) {
    throw new Error(
      "useAllCameraPageProvider must be used within a AllCameraPageProvider"
    );
  }
  return context;
}

export { AllCameraPageProvider, useAllCameraPageProvider };
