import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AllCamerasPage from "./pages/AllCamerasPage";
import { EditROIProvider } from "./EditROI/EditROIProvider";
import SelectedCamPage from "./pages/SelectedCamPage";
import ConfirmContextProvider from "./Confirm/ConfirmProvider";
import Confirm from "./Confirm";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <AllCamerasPage />,
  },
  {
    path: "/selected/:c_id",
    element: (
      <EditROIProvider>
        <SelectedCamPage />
      </EditROIProvider>
    ),
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfirmContextProvider>
        <RouterProvider router={router} />
        <Confirm />
      </ConfirmContextProvider>
    </Provider>
  </React.StrictMode>
);
