import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCameras } from "../../store/slices/CamerasSlice";
import { getAllCameras as getAllCamerasReq } from "../../utils/api";
import { useAllCameraPageProvider } from "./AllCameraPageProvider";

const useAllCamerasPage = () => {
  // state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // custom hooks
  const { setShowAddForm } = useAllCameraPageProvider();

  // module hooks
  const dispatch = useAppDispatch();
  const { cameras } = useAppSelector((state) => state.cameras);

  // click listeners
  const addCamClick = () => {
    setShowAddForm(true);
  };

  // utils
  const startRequest = () => {
    setError("");
    setLoading(true);
  };
  const getAllCameras = async () => {
    startRequest();
    try {
      const { data } = await getAllCamerasReq();
      dispatch(setCameras(data.cameras));
    } catch (e: any) {
      setError(e.response.data?.message || e.response.status);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    cameras,
    addCamClick,
    getAllCameras,
  };
};

export default useAllCamerasPage;
