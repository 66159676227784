import { Paper, Backdrop, TextField, Alert } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./AddCameraForm.module.scss";
import useAddCameraForm from "./useAddCameraForm";
import ClickOutside from "../../ClickOutside";

const AddCameraForm = () => {
  const { formik, loading, showAddForm, handleClose, error } =
    useAddCameraForm();
  return (
    <Backdrop open={showAddForm} sx={{ zIndex: 200 }}>
      <ClickOutside foo={handleClose}>
        <Paper
          className={styles["container"]}
          component="form"
          onSubmit={formik.handleSubmit}
        >
          <h1>Добавить новую камеру</h1>
          {error && <Alert severity="error">{error}</Alert>}
          <TextField
            required
            label="Название"
            id="name"
            name="name"
            value={formik.values["name"]}
            onChange={formik.handleChange}
            fullWidth
          />
          <TextField
            required
            label="RTSP URL"
            id="video_url"
            name="video_url"
            value={formik.values["video_url"]}
            onChange={formik.handleChange}
            fullWidth
          />
          <LoadingButton type="submit" loading={loading} variant="contained">
            Сохранить
          </LoadingButton>
        </Paper>
      </ClickOutside>
    </Backdrop>
  );
};

export default AddCameraForm;
