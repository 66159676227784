import { Alert, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Point } from "../EditROI/EditROIProvider";
import useCamImg from "./useCamImg";
import styles from "./CamImg.module.scss";
import { createPolygonCoords } from "../EditROI/functions";
import { bbox } from "../pages/SelectedCamPage/useSelectedCamPage";
import { API_URL } from "../utils/constants";

interface CamImgProps extends React.HTMLAttributes<HTMLDivElement> {
  c_id: string;
  display_polygon?: 0 | 1;
  bboxlist?: bbox[];
  result_id?: string;
}

const CamImg = (props: CamImgProps) => {
  const { display_polygon, c_id, bboxlist, result_id } = props;
  const { imgHref, imgParams, loading, cameras, error, setImageByUrl } =
    useCamImg();
  const [points, setPoints] = useState<Point[] | null>();
  const [initialLoad, setInitialLoad] = useState(true);

  // используется промежуточное состояние, чтобы новые bbox не отрисовывались
  // пока не отрисуется event frame
  const [currentBboxes, setCurrentBboxes] = useState<bbox[]>([]);

  const imageRef = useCallback(
    async (node: SVGImageElement) => {
      if (node !== null) {
        const url = initialLoad
          ? `${API_URL}/cameras/${c_id}/frame`
          : `${API_URL}/results/${result_id}/overview`;
        await setImageByUrl(url, node, 0);
        bboxlist && setCurrentBboxes(bboxlist);
        initialLoad && setInitialLoad(false);
      }
    },
    [result_id]
  );

  useEffect(() => {
    const camera = cameras.filter((cam) => cam.id === c_id)[0];
    setPoints(camera?.person_detection_parameters?.roi);
  }, [cameras]);

  return (
    <div className={styles["container"]} {...props}>
      {loading && <CircularProgress sx={{ position: "absolute" }} />}
      {error && <Alert severity="error">{error}</Alert>}
      <svg
        viewBox={`0 0 ${imgParams.w} ${imgParams.h}`}
        style={{ display: error ? "none" : "" }}
      >
        <image ref={imageRef} href={imgHref} />
        {display_polygon && points && (
          <>
            {Object.values(points).length > 2 && (
              <polygon
                points={createPolygonCoords(points)}
                fillOpacity={0}
                stroke="blue"
                strokeWidth={4}
              />
            )}
          </>
        )}
        {currentBboxes && (
          <>
            {currentBboxes.map((bbox, i) => (
              <rect
                x={bbox[0]}
                y={bbox[1]}
                width={bbox[2]}
                height={bbox[3]}
                fillOpacity={0}
                stroke="red"
                key={i}
              />
            ))}
          </>
        )}
      </svg>
    </div>
  );
};

export default CamImg;
